/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportTask } from './ReportTask';
import {
    ReportTaskFromJSON,
    ReportTaskFromJSONTyped,
    ReportTaskToJSON,
} from './ReportTask';
import type { ReportTaskResultObjectLists } from './ReportTaskResultObjectLists';
import {
    ReportTaskResultObjectListsFromJSON,
    ReportTaskResultObjectListsFromJSONTyped,
    ReportTaskResultObjectListsToJSON,
} from './ReportTaskResultObjectLists';
import type { RiskMatrix } from './RiskMatrix';
import {
    RiskMatrixFromJSON,
    RiskMatrixFromJSONTyped,
    RiskMatrixToJSON,
} from './RiskMatrix';

/**
 * 
 * @export
 * @interface ReportTaskResult
 */
export interface ReportTaskResult {
    /**
     * 
     * @type {ReportTask}
     * @memberof ReportTaskResult
     */
    object?: ReportTask;
    /**
     * 
     * @type {ReportTaskResultObjectLists}
     * @memberof ReportTaskResult
     */
    objectLists?: ReportTaskResultObjectLists;
    /**
     * 
     * @type {RiskMatrix}
     * @memberof ReportTaskResult
     */
    riskMatrix?: RiskMatrix;
}

/**
 * Check if a given object implements the ReportTaskResult interface.
 */
export function instanceOfReportTaskResult(value: object): value is ReportTaskResult {
    return true;
}

export function ReportTaskResultFromJSON(json: any): ReportTaskResult {
    return ReportTaskResultFromJSONTyped(json, false);
}

export function ReportTaskResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportTaskResult {
    if (json == null) {
        return json;
    }
    return {
        
        'object': json['object'] == null ? undefined : ReportTaskFromJSON(json['object']),
        'objectLists': json['objectLists'] == null ? undefined : ReportTaskResultObjectListsFromJSON(json['objectLists']),
        'riskMatrix': json['risk_matrix'] == null ? undefined : RiskMatrixFromJSON(json['risk_matrix']),
    };
}

export function ReportTaskResultToJSON(value?: ReportTaskResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object': ReportTaskToJSON(value['object']),
        'objectLists': ReportTaskResultObjectListsToJSON(value['objectLists']),
        'risk_matrix': RiskMatrixToJSON(value['riskMatrix']),
    };
}

