/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentInfoResult
 */
export interface DocumentInfoResult {
    /**
     * 
     * @type {string}
     * @memberof DocumentInfoResult
     */
    authorName?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfoResult
     */
    authorEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfoResult
     */
    lastUpdateUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentInfoResult
     */
    lastUpdateUserEmail?: string;
    /**
     * 
     * @type {Date}
     * @memberof DocumentInfoResult
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DocumentInfoResult
     */
    lastUpdateTime?: Date;
}

/**
 * Check if a given object implements the DocumentInfoResult interface.
 */
export function instanceOfDocumentInfoResult(value: object): value is DocumentInfoResult {
    return true;
}

export function DocumentInfoResultFromJSON(json: any): DocumentInfoResult {
    return DocumentInfoResultFromJSONTyped(json, false);
}

export function DocumentInfoResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentInfoResult {
    if (json == null) {
        return json;
    }
    return {
        
        'authorName': json['author_name'] == null ? undefined : json['author_name'],
        'authorEmail': json['author_email'] == null ? undefined : json['author_email'],
        'lastUpdateUserName': json['last_update_user_name'] == null ? undefined : json['last_update_user_name'],
        'lastUpdateUserEmail': json['last_update_user_email'] == null ? undefined : json['last_update_user_email'],
        'creationTime': json['creation_time'] == null ? undefined : (new Date(json['creation_time'])),
        'lastUpdateTime': json['last_update_time'] == null ? undefined : (new Date(json['last_update_time'])),
    };
}

export function DocumentInfoResultToJSON(value?: DocumentInfoResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'author_name': value['authorName'],
        'author_email': value['authorEmail'],
        'last_update_user_name': value['lastUpdateUserName'],
        'last_update_user_email': value['lastUpdateUserEmail'],
        'creation_time': value['creationTime'] == null ? undefined : ((value['creationTime']).toISOString()),
        'last_update_time': value['lastUpdateTime'] == null ? undefined : ((value['lastUpdateTime']).toISOString()),
    };
}

