/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportTaskActionRequest
 */
export interface ReportTaskActionRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportTaskActionRequest
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportTaskActionRequest
     */
    reportType?: ReportTaskActionRequestReportTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportTaskActionRequest
     */
    type?: ReportTaskActionRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportTaskActionRequest
     */
    action?: ReportTaskActionRequestActionEnum;
}


/**
 * @export
 */
export const ReportTaskActionRequestReportTypeEnum = {
    ImpactAssessment: 'ImpactAssessment',
    ControlsSummary: 'ControlsSummary',
    Compliance: 'Compliance',
    Stakeholder: 'Stakeholder',
    RegionStakeholder: 'RegionStakeholder',
    GisImpactAssessment: 'GisImpactAssessment'
} as const;
export type ReportTaskActionRequestReportTypeEnum = typeof ReportTaskActionRequestReportTypeEnum[keyof typeof ReportTaskActionRequestReportTypeEnum];

/**
 * @export
 */
export const ReportTaskActionRequestTypeEnum = {
    AspectActivity: 'AspectActivity',
    Receptor: 'Receptor',
    Impact: 'Impact',
    Control: 'Control',
    Risk: 'Risk',
    EnvironmentalInputs: 'EnvironmentalInputs',
    TechnicalInputs: 'TechnicalInputs',
    ActivityAspectAreas: 'ActivityAspectAreas',
    Activity: 'Activity',
    Compliance: 'Compliance',
    Stakeholder: 'Stakeholder'
} as const;
export type ReportTaskActionRequestTypeEnum = typeof ReportTaskActionRequestTypeEnum[keyof typeof ReportTaskActionRequestTypeEnum];

/**
 * @export
 */
export const ReportTaskActionRequestActionEnum = {
    DefaultYes: 'DefaultYes',
    DefaultNo: 'DefaultNo',
    ClearAll: 'ClearAll'
} as const;
export type ReportTaskActionRequestActionEnum = typeof ReportTaskActionRequestActionEnum[keyof typeof ReportTaskActionRequestActionEnum];


/**
 * Check if a given object implements the ReportTaskActionRequest interface.
 */
export function instanceOfReportTaskActionRequest(value: object): value is ReportTaskActionRequest {
    return true;
}

export function ReportTaskActionRequestFromJSON(json: any): ReportTaskActionRequest {
    return ReportTaskActionRequestFromJSONTyped(json, false);
}

export function ReportTaskActionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportTaskActionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'projectId': json['project_id'] == null ? undefined : json['project_id'],
        'reportType': json['report_type'] == null ? undefined : json['report_type'],
        'type': json['type'] == null ? undefined : json['type'],
        'action': json['action'] == null ? undefined : json['action'],
    };
}

export function ReportTaskActionRequestToJSON(value?: ReportTaskActionRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'project_id': value['projectId'],
        'report_type': value['reportType'],
        'type': value['type'],
        'action': value['action'],
    };
}

