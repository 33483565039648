/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportAnswer } from './ReportAnswer';
import {
    ReportAnswerFromJSON,
    ReportAnswerFromJSONTyped,
    ReportAnswerToJSON,
} from './ReportAnswer';
import type { EnvidDocumentInfo } from './EnvidDocumentInfo';
import {
    EnvidDocumentInfoFromJSON,
    EnvidDocumentInfoFromJSONTyped,
    EnvidDocumentInfoToJSON,
} from './EnvidDocumentInfo';
import type { TaskStructureItem } from './TaskStructureItem';
import {
    TaskStructureItemFromJSON,
    TaskStructureItemFromJSONTyped,
    TaskStructureItemToJSON,
} from './TaskStructureItem';

/**
 * 
 * @export
 * @interface ReportTask
 */
export interface ReportTask {
    /**
     * 
     * @type {string}
     * @memberof ReportTask
     */
    type?: ReportTaskTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ReportTask
     */
    percentage?: number;
    /**
     * 
     * @type {number}
     * @memberof ReportTask
     */
    unknownAnswers?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportTask
     */
    status?: ReportTaskStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportTask
     */
    waitingFor?: Array<ReportTaskWaitingForEnum>;
    /**
     * 
     * @type {EnvidDocumentInfo}
     * @memberof ReportTask
     */
    documentInfo?: EnvidDocumentInfo;
    /**
     * 
     * @type {Date}
     * @memberof ReportTask
     */
    structureUpdateTime?: Date;
    /**
     * 
     * @type {{ [key: string]: ReportAnswer; }}
     * @memberof ReportTask
     */
    answers?: { [key: string]: ReportAnswer; };
    /**
     * 
     * @type {TaskStructureItem}
     * @memberof ReportTask
     */
    structure?: TaskStructureItem;
    /**
     * 
     * @type {number}
     * @memberof ReportTask
     */
    numAnswers?: number;
}


/**
 * @export
 */
export const ReportTaskTypeEnum = {
    AspectActivity: 'AspectActivity',
    Receptor: 'Receptor',
    Impact: 'Impact',
    Control: 'Control',
    Risk: 'Risk',
    EnvironmentalInputs: 'EnvironmentalInputs',
    TechnicalInputs: 'TechnicalInputs',
    ActivityAspectAreas: 'ActivityAspectAreas',
    Activity: 'Activity',
    Compliance: 'Compliance',
    Stakeholder: 'Stakeholder'
} as const;
export type ReportTaskTypeEnum = typeof ReportTaskTypeEnum[keyof typeof ReportTaskTypeEnum];

/**
 * @export
 */
export const ReportTaskStatusEnum = {
    New: 'New',
    Pending: 'Pending',
    Completed: 'Completed',
    Unavailable: 'Unavailable',
    Empty: 'Empty',
    Loading: 'Loading'
} as const;
export type ReportTaskStatusEnum = typeof ReportTaskStatusEnum[keyof typeof ReportTaskStatusEnum];

/**
 * @export
 */
export const ReportTaskWaitingForEnum = {
    AspectActivity: 'AspectActivity',
    Receptor: 'Receptor',
    Impact: 'Impact',
    Control: 'Control',
    Risk: 'Risk',
    EnvironmentalInputs: 'EnvironmentalInputs',
    TechnicalInputs: 'TechnicalInputs',
    ActivityAspectAreas: 'ActivityAspectAreas',
    Activity: 'Activity',
    Compliance: 'Compliance',
    Stakeholder: 'Stakeholder'
} as const;
export type ReportTaskWaitingForEnum = typeof ReportTaskWaitingForEnum[keyof typeof ReportTaskWaitingForEnum];


/**
 * Check if a given object implements the ReportTask interface.
 */
export function instanceOfReportTask(value: object): value is ReportTask {
    return true;
}

export function ReportTaskFromJSON(json: any): ReportTask {
    return ReportTaskFromJSONTyped(json, false);
}

export function ReportTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportTask {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'percentage': json['percentage'] == null ? undefined : json['percentage'],
        'unknownAnswers': json['unknown_answers'] == null ? undefined : json['unknown_answers'],
        'status': json['status'] == null ? undefined : json['status'],
        'waitingFor': json['waitingFor'] == null ? undefined : json['waitingFor'],
        'documentInfo': json['document_info'] == null ? undefined : EnvidDocumentInfoFromJSON(json['document_info']),
        'structureUpdateTime': json['structureUpdateTime'] == null ? undefined : (new Date(json['structureUpdateTime'])),
        'answers': json['answers'] == null ? undefined : (mapValues(json['answers'], ReportAnswerFromJSON)),
        'structure': json['structure'] == null ? undefined : TaskStructureItemFromJSON(json['structure']),
        'numAnswers': json['num_answers'] == null ? undefined : json['num_answers'],
    };
}

export function ReportTaskToJSON(value?: ReportTask | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'percentage': value['percentage'],
        'unknown_answers': value['unknownAnswers'],
        'status': value['status'],
        'waitingFor': value['waitingFor'],
        'document_info': EnvidDocumentInfoToJSON(value['documentInfo']),
        'structureUpdateTime': value['structureUpdateTime'] == null ? undefined : ((value['structureUpdateTime']).toISOString()),
        'answers': value['answers'] == null ? undefined : (mapValues(value['answers'], ReportAnswerToJSON)),
        'structure': TaskStructureItemToJSON(value['structure']),
        'num_answers': value['numAnswers'],
    };
}

