/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DocumentInfoResult } from './DocumentInfoResult';
import {
    DocumentInfoResultFromJSON,
    DocumentInfoResultFromJSONTyped,
    DocumentInfoResultToJSON,
} from './DocumentInfoResult';

/**
 * 
 * @export
 * @interface ReportSummary
 */
export interface ReportSummary {
    /**
     * 
     * @type {string}
     * @memberof ReportSummary
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSummary
     */
    desc?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSummary
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSummary
     */
    instanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSummary
     */
    templateId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSummary
     */
    documentType?: ReportSummaryDocumentTypeEnum;
    /**
     * 
     * @type {DocumentInfoResult}
     * @memberof ReportSummary
     */
    documentInfo?: DocumentInfoResult;
}


/**
 * @export
 */
export const ReportSummaryDocumentTypeEnum = {
    WordDocx: 'WORD_DOCX',
    ExcelXlsx: 'EXCEL_XLSX',
    Csv: 'CSV',
    Zip: 'ZIP'
} as const;
export type ReportSummaryDocumentTypeEnum = typeof ReportSummaryDocumentTypeEnum[keyof typeof ReportSummaryDocumentTypeEnum];


/**
 * Check if a given object implements the ReportSummary interface.
 */
export function instanceOfReportSummary(value: object): value is ReportSummary {
    return true;
}

export function ReportSummaryFromJSON(json: any): ReportSummary {
    return ReportSummaryFromJSONTyped(json, false);
}

export function ReportSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'desc': json['desc'] == null ? undefined : json['desc'],
        'status': json['status'] == null ? undefined : json['status'],
        'instanceId': json['instance_id'] == null ? undefined : json['instance_id'],
        'templateId': json['template_id'] == null ? undefined : json['template_id'],
        'documentType': json['document_type'] == null ? undefined : json['document_type'],
        'documentInfo': json['documentInfo'] == null ? undefined : DocumentInfoResultFromJSON(json['documentInfo']),
    };
}

export function ReportSummaryToJSON(value?: ReportSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'desc': value['desc'],
        'status': value['status'],
        'instance_id': value['instanceId'],
        'template_id': value['templateId'],
        'document_type': value['documentType'],
        'documentInfo': DocumentInfoResultToJSON(value['documentInfo']),
    };
}

