/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportInstanceStatusRequest
 */
export interface ReportInstanceStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportInstanceStatusRequest
     */
    instanceId?: string;
}

/**
 * Check if a given object implements the ReportInstanceStatusRequest interface.
 */
export function instanceOfReportInstanceStatusRequest(value: object): value is ReportInstanceStatusRequest {
    return true;
}

export function ReportInstanceStatusRequestFromJSON(json: any): ReportInstanceStatusRequest {
    return ReportInstanceStatusRequestFromJSONTyped(json, false);
}

export function ReportInstanceStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportInstanceStatusRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'instanceId': json['instance_id'] == null ? undefined : json['instance_id'],
    };
}

export function ReportInstanceStatusRequestToJSON(value?: ReportInstanceStatusRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'instance_id': value['instanceId'],
    };
}

