/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportSummary } from './ReportSummary';
import {
    ReportSummaryFromJSON,
    ReportSummaryFromJSONTyped,
    ReportSummaryToJSON,
} from './ReportSummary';

/**
 * 
 * @export
 * @interface EbaseResultListReportSummary
 */
export interface EbaseResultListReportSummary {
    /**
     * 
     * @type {boolean}
     * @memberof EbaseResultListReportSummary
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EbaseResultListReportSummary
     */
    error?: string;
    /**
     * 
     * @type {Array<ReportSummary>}
     * @memberof EbaseResultListReportSummary
     */
    data?: Array<ReportSummary>;
}

/**
 * Check if a given object implements the EbaseResultListReportSummary interface.
 */
export function instanceOfEbaseResultListReportSummary(value: object): value is EbaseResultListReportSummary {
    return true;
}

export function EbaseResultListReportSummaryFromJSON(json: any): EbaseResultListReportSummary {
    return EbaseResultListReportSummaryFromJSONTyped(json, false);
}

export function EbaseResultListReportSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EbaseResultListReportSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'] == null ? undefined : json['success'],
        'error': json['error'] == null ? undefined : json['error'],
        'data': json['data'] == null ? undefined : ((json['data'] as Array<any>).map(ReportSummaryFromJSON)),
    };
}

export function EbaseResultListReportSummaryToJSON(value?: EbaseResultListReportSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'error': value['error'],
        'data': value['data'] == null ? undefined : ((value['data'] as Array<any>).map(ReportSummaryToJSON)),
    };
}

