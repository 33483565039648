import {Store} from "redux";
import {
  ConfigurationParameters,
  EbaseAuthenticateWSApi,
  Configuration,
  SiteDataResult,
  EbaseEnvidAdminWSApi,
  EnvidObjectResult,
  EnvidObjectSmallResult,
  EbaseCoreWSApi,
  AuditEventPublic,
  DatasetPublishingInfoResult,
  CollatedObjectSummary,
  UserResult,
  ReportTemplateResult,
  DatasetResult,
  SearchDatasetAuditLogTypeEnum,
  EbaseDataSummaryWSApi,
  NotifyTemplateResult, UpdateFieldsRequest,
  CheckReportLoadReportTypeEnum,
  ReportResult, EbaseEnvidClientWSApi
} from "../fetchapi";
import SessionManager from "./SessionManager";
class EbaseDS {

  private baseUrl: string;
  private store;

  private authWs: EbaseAuthenticateWSApi;
  private envidAdminWS: EbaseEnvidAdminWSApi;
  private coreWS: EbaseCoreWSApi;
  private summaryWS: EbaseDataSummaryWSApi;
  private clientWs: EbaseEnvidClientWSApi;

  constructor(appBase: string, store: Store<any>) {
    console.log(' appBase', appBase);
    this.baseUrl = appBase;
    this.store = store;
    const parameters: ConfigurationParameters = {};
    parameters.basePath = appBase;
    const config: Configuration = new Configuration(parameters);

    this.authWs = new EbaseAuthenticateWSApi(config);
    this.envidAdminWS = new EbaseEnvidAdminWSApi(config);
    this.coreWS = new EbaseCoreWSApi(config);
    this.summaryWS = new EbaseDataSummaryWSApi(config);
    this.clientWs = new EbaseEnvidClientWSApi(config);

  }

  public loginSession(email: string, password: string, callback: (res?: SiteDataResult | null, error?: string | null) => void): void{
    this.authWs.authenticate({username: email, password: password}).then(result => {
      if (result.success){
        callback(result.data);
      }else{
        callback(null, result.error);
      }
    }).catch(error => {
      console.log(' auth catch', error);
      let msg = "Connection Error";
      if (error != null){
        msg = msg + ": "+error.response?.status + " "+error.response?.statusText;
      }
      callback(null, msg);
      this.handleResponseError(error);
    });

  }

  // public loginSessionPromise(email: string, password: string): Promise<SiteDataResult>{
  //
  //   this.authWs.authenticate({username: email, password: password}).then(result => {
  //     if (result.success){
  //       return Promise.resolve(result.data);
  //     }else{
  //       return Promise.reject(result.error);
  //     }
  //   }).catch(error => {
  //     console.log(' auth catch', error);
  //     let msg = "Connection Error";
  //     if (error != null){
  //       msg = msg + ": "+error.response?.status + " "+error.response?.statusText;
  //     }
  //     this.handleResponseError(error);
  //     return Promise.reject(msg);
  //   });
  //
  // }

  /**
   * Checks out an object from the system using the provided shared ID.
   *
   * @param {string} sharedId - The unique identifier for the shared object to be checked out.
   * @return {Promise<EnvidObjectResult>} A promise that resolves to the result of the checkout operation.
   */
  public checkoutObject(sharedId: string): Promise<EnvidObjectResult>{

    return new Promise<EnvidObjectResult>((resolve, reject) => {
      this.envidAdminWS.checkoutObject(sharedId).then(result => {

        if (result.success){
          // @ts-ignore
          resolve(result.data);
        }else{
          reject(result.error);
        }

      }).catch(error => {
        console.log(' checkout catch', error);
        let msg = "Connection Error";
        if (error != null){
          msg = msg + ": "+error.response?.status + " "+error.response?.statusText;
        }
        this.handleResponseError(error);
        // reject(msg);
      });
    });
  }

  /**
   * Checks in an object with the given shared ID.
   *
   * @param {string} sharedId - The shared ID of the object to check in.
   * @return {Promise<EnvidObjectResult>} A promise that resolves with the result of the check-in operation.
   */
  public checkinObject(sharedId: string): Promise<EnvidObjectResult>{

    return new Promise<EnvidObjectResult>((resolve, reject) => {
      this.envidAdminWS.checkinObject(sharedId).then(result => {

        if (result.success){
          // @ts-ignore
          resolve(result.data);
        }else{
          reject(result.error);
        }

      }).catch(error => {
        console.log(' checkin catch', error);
        let msg = "Connection Error";
        if (error != null){
          msg = msg + ": "+error.response?.status + " "+error.response?.statusText;
        }
        this.handleResponseError(error);
        // reject(msg);
      });
    });
  }

  public loadDatasetChangedObjects(versionId: string): Promise<EnvidObjectSmallResult[]>{
    return new Promise<EnvidObjectSmallResult[]>((resolve, reject) => {
          this.coreWS.getChangedObjectsForSessionDataset(versionId).then(result => {

            if (result.success){
              // @ts-ignore
              resolve(result.data);
            }else{
              reject(result.error);
            }

          }).catch(error => {
            console.log(' loadDatasetChangedObjects catch', error);
            let msg = "Connection Error";
            if (error != null){
              msg = msg + ": "+error.response?.status + " "+error.response?.statusText;
            }
            this.handleResponseError(error);
            reject(msg);
          })
        });
  }

  /**
   * Loads the workflow history for a given object by its shared ID. Only includes status changes, publish events and
   * the create event.
   *
   * @param {string} sharedId - The shared ID of the object to retrieve the workflow history for.
   * @return {Promise<AuditEventPublic[]>} A promise that resolves to an array of AuditEventPublic objects
   * representing the workflow history. It rejects with an error message if the operation fails.
   */
  public loadObjectWorkflowHistory(sharedId: string): Promise<AuditEventPublic[]>{
    return new Promise<AuditEventPublic[]>((resolve, reject) => {
      this.envidAdminWS.getWorkflowHistory(sharedId).then(result => {

        if (result.success){
          resolve(result.data ?? []);
        }else{
          reject(result.error);
        }
      }).catch(error => {
        console.log(' loadDatasetChangedObjects catch', error);
        let msg = "Connection Error";
        if (error != null){
          msg = msg + ": "+error.response?.status + " "+error.response?.statusText;
        }
        this.handleResponseError(error);
        reject(msg);
      });
    });
  }

  public loadObjectChangeHistory(sharedId: string): Promise<AuditEventPublic[]>{
    return new Promise<AuditEventPublic[]>((resolve, reject) => {
      this.envidAdminWS.getChangeHistory(sharedId).then(result => {

        if (result.success){
          resolve(result.data ?? []);
        }else{
          reject(result.error);
        }
      }).catch(error => {
        console.log(' loadObjectChangeHistory catch', error);
        let msg = "Connection Error";
        if (error != null){
          msg = msg + ": "+error.response?.status + " "+error.response?.statusText;
        }
        this.handleResponseError(error);
        reject(msg);
      });
    });
  }

  public loadDatasetPublishingInfo(): Promise<DatasetPublishingInfoResult> {
    return new Promise<DatasetPublishingInfoResult>((resolve, reject) => {
      this.coreWS.getSessionDatasetPublishing().then(result => {
        if (result.success){
          // @ts-ignore
          resolve(result.data);
        } else {
          reject(result.error);
        }
      }).catch(error => {
        console.log(' loadDatasetPublishingInfo catch', error);
        this.handleResponseError(error);
        reject("There was a server error loading data");
      })
    })
  }

  public publishCurrentDataset(version: string, notes: string | undefined, notifyUsers: boolean, sharedIds: string[]): Promise<EnvidObjectSmallResult[]>{
    return new Promise<EnvidObjectSmallResult[]>((resolve, reject) => {

      this.coreWS.publishCurrentDataset({
        version: version,
        notes: notes,
        notifyUsers: notifyUsers,
        selectedObjects: sharedIds}).then(result => {
          if (result.success){
            resolve(result.data ?? []);
          }else{
            reject(result.error);
          }
      }).catch(error => {
        console.log(' publishCurrentDataset catch', error);
        this.handleResponseError(error);
        reject("Server error publishing library "+ (error.message ?? ''));
      });

    });
  }

  public getPublishedVersionComparison(sharedId: string): Promise<CollatedObjectSummary> {
    return new Promise<CollatedObjectSummary>((resolve, reject) => {
      this.envidAdminWS.getVersionChanges(sharedId).then(result => {
        if (result.success){
          resolve(result.data!);
        }else{
          reject(result.error);
        }
      }).catch(error => {
        console.log(' getPublishedVersionComparison catch', error);
        this.handleResponseError(error);
        reject("Server error getting published version comparison " + (error.message ?? ''));
      });
    });
  }

  public getUsersWithAccessToCurrentDataset(): Promise<UserResult[]>{
    return new Promise<UserResult[]>((resolve, reject) => {
      this.coreWS.getDatasetUsers().then(result => {
        if (result.success){
          resolve(result.data!);
        }else{
          reject(result.error);
        }
      }).catch( error => {
        console.log(' getUsersWithAccessToCurrentDataset catch', error);
        this.handleResponseError(error);
        reject("Server error getting published version comparison " + (error.message ?? ''));
      });
    });
  }

  public restoreDeletedObject(sharedId: string): Promise<EnvidObjectResult> {
    return new Promise<EnvidObjectResult>((resolve, reject) => {
      this.envidAdminWS.restoreDeletedObject({objectId: sharedId}).then(result => {
        if (result.success){
          resolve(result.data!);
        } else {
          reject(result.error);
        }
      }).catch( error => {
        console.log(' restoreDeletedObject catch', error);
        this.handleResponseError(error);
        reject("Server error restoring deleted object  " + (error.message ?? ''));
      });
    })
  }

  public changeObjectOwner(sharedId: string, ownerId: string): Promise<EnvidObjectResult> {
    return new Promise<EnvidObjectResult>((resolve, reject) => {
      this.envidAdminWS.changeObjectOwner(sharedId, {objectId: ownerId}).then(result => {
        if (result.success){
          resolve(result.data!);
        } else {
          reject(result.error);
        }
      }).catch( error => {
        console.log(' changeObjectOwner catch', error);
        this.handleResponseError(error);
        reject("Server error changing object owner " + (error.message ?? ''));
      });
    })
  }

  public changeLibraryOwner(ownerId: string): Promise<DatasetResult> {
    return new Promise<DatasetResult>((resolve, reject) => {
      this.coreWS.changeDatasetOwner({objectId: ownerId}).then(result => {
        if (result.success){
          resolve(result.data!);
        } else {
          reject(result.error);
        }
      }).catch( error => {
        console.log(' changeLibraryOwner catch', error);
        this.handleResponseError(error);
        reject("Server error changing object owner " + (error.message ?? ''));
      })
    });
  }

  public loadReportTemplates(): Promise<ReportTemplateResult[]>{
    return new Promise<ReportTemplateResult[]>((resolve, reject) => {
      this.coreWS.getTemplates().then(result => {
        if (result.success){
          resolve(result.data!);
        } else {
          reject(result.error);
        }
      }).catch(error => {
        console.log(' loadReportTemplates catch', error);
        this.handleResponseError(error);
        reject("Server error getting templates " + (error.message ?? ''));
      })
    })
  }

  public deleteTemplate(templateId: string): Promise<ReportTemplateResult[]>{
    return new Promise<ReportTemplateResult[]>((resolve, reject) => {
      this.coreWS.deleteTemplate(templateId).then(result => {
        if (result.success){
          resolve(result.data!);
        } else {
          reject(result.error);
        }
      }).catch(error => {
        console.log(' deleteTemplate catch', error);
        this.handleResponseError(error);
        reject("Server error deleting template " + (error.message ?? ''));
      })
    })
  }

  public searchAuditEvents(type: SearchDatasetAuditLogTypeEnum | null, user: string | null, maxResults: number, page: number): Promise<AuditEventPublic[]>{
    return new Promise<AuditEventPublic[]>((resolve, reject) => {
      this.coreWS.searchDatasetAuditLog(type ?? undefined, user ?? undefined, maxResults, page).then(result => {

        if (result.success){
          resolve(result.data!);
        } else {
          reject(result.error);
        }

      }).catch(error => {
        console.log(' searchAuditEvents catch', error);
        this.handleResponseError(error);
        reject("Server error searching AuditEvents " + (error.message ?? ''));
      })
    })
  }

  public loadLibrarySummary(): Promise<{ [key: string]: number; }>{
    return new Promise<{ [key: string]: number; }>((resolve, reject) => {
      this.summaryWS.loadLibraryPublishSummary().then(result => {
        if (result.success){
          resolve(result.data!)
        } else {
          reject(result.error);
        }
      }).catch(error => {
        console.log(' loadLibrarySummary', error);
        this.handleResponseError(error);
        reject("Server error loading LibrarySummary" + (error.message ?? ''));
      })
    })
  }

  public loadEmailTemplates(): Promise<NotifyTemplateResult[]>{
    return new Promise<NotifyTemplateResult[]>((resolve, reject) => {
      this.coreWS.getNotifyTemplates().then(result => {
        if (result.success) {
          resolve(result.data!);
        } else {
          reject(result.error);
        }
      }).catch(error => {
        console.log(' loadEmailTemplates catch', error);
        this.handleResponseError(error);
        reject("Server error loadEmailTemplates " + (error.message ?? ''));
      })
    })
  }

  public setEmailTemplates(code: string, template: NotifyTemplateResult): Promise<NotifyTemplateResult> {
    return new Promise<NotifyTemplateResult>((resolve, reject) => {
      this.coreWS.updateNotifyTemplate(code, template).then(result => {
        if (result.success) {
          resolve(result.data!);
        } else {
          reject(result.error);
        }
      }).catch(error => {
        console.log(' loadEmailTemplates catch', error);
        this.handleResponseError(error);
        reject("Server error loadEmailTemplates " + (error.message ?? ''));
      })
    })
  }

  public updateProjectArchiveState(projectId: string, archived: boolean): Promise<void> {
    const req: UpdateFieldsRequest = {
      objectId: projectId,
      field: 'details.archived',
      // @ts-ignore
      value: archived + ''
    }

    return new Promise<void>((resolve, reject) => {
      this.coreWS.updateProjectFields([req]).then(result => {
        if (result.success) {
          resolve();
        }else{
          reject(result.error);
        }
      }).catch(error => {
        console.log('updateProjectArchiveState catch', error);
        this.handleResponseError(error);
        reject("Server error updateProjectArchiveState " + (error.message ?? ''));
      });
    });
  }

  public checkReportLoad(projectId: string, reportType: CheckReportLoadReportTypeEnum): Promise<ReportResult>{
    return new Promise<ReportResult>((resolve, reject) => {
      this.clientWs.checkReportLoad(projectId, reportType).then(result => {
        if (result.success){
          resolve(result.data!);
        }else{
          reject(result.error);
        }
      }).catch(error => {
        console.log('checkReportLoad catch', error);
        this.handleResponseError(error);
        reject("Server error checkReportLoad " + (error.message ?? ''));
      })
    });
  }

  private handleResponseError(error: any): void {
    console.log(' handleResponseError', error);
    if (error?.response?.status === 401) {
      window.location.reload();
    }

    if (error?.response?.status === 400) {
      SessionManager.shared().showToast("Error", "Bad Request", 'danger');
    }

    if (error?.response?.status === 403) {
      SessionManager.shared().showToast("Not Allowed", "Your user does not have permission to do that", 'warning');
    }

    if (error?.response?.status >= 500 && error?.response?.status < 600) {
      SessionManager.shared().showToast("Error", "The server returned an error  ("+error?.response?.status+") ", 'danger');
    }
  }
}

export default EbaseDS;
