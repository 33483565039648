/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenerateReportInstancesRequest
 */
export interface GenerateReportInstancesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateReportInstancesRequest
     */
    instanceIds?: Array<string>;
}

/**
 * Check if a given object implements the GenerateReportInstancesRequest interface.
 */
export function instanceOfGenerateReportInstancesRequest(value: object): value is GenerateReportInstancesRequest {
    return true;
}

export function GenerateReportInstancesRequestFromJSON(json: any): GenerateReportInstancesRequest {
    return GenerateReportInstancesRequestFromJSONTyped(json, false);
}

export function GenerateReportInstancesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateReportInstancesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'instanceIds': json['instance_ids'] == null ? undefined : json['instance_ids'],
    };
}

export function GenerateReportInstancesRequestToJSON(value?: GenerateReportInstancesRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'instance_ids': value['instanceIds'],
    };
}

