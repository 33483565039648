/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnvidDocumentInfo } from './EnvidDocumentInfo';
import {
    EnvidDocumentInfoFromJSON,
    EnvidDocumentInfoFromJSONTyped,
    EnvidDocumentInfoToJSON,
} from './EnvidDocumentInfo';

/**
 * 
 * @export
 * @interface TaskSummary
 */
export interface TaskSummary {
    /**
     * 
     * @type {string}
     * @memberof TaskSummary
     */
    type?: TaskSummaryTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskSummary
     */
    status?: TaskSummaryStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof TaskSummary
     */
    percentage?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskSummary
     */
    waitingFor?: Array<TaskSummaryWaitingForEnum>;
    /**
     * 
     * @type {number}
     * @memberof TaskSummary
     */
    unknownAnswers?: number;
    /**
     * 
     * @type {EnvidDocumentInfo}
     * @memberof TaskSummary
     */
    documentInfo?: EnvidDocumentInfo;
}


/**
 * @export
 */
export const TaskSummaryTypeEnum = {
    AspectActivity: 'AspectActivity',
    Receptor: 'Receptor',
    Impact: 'Impact',
    Control: 'Control',
    Risk: 'Risk',
    EnvironmentalInputs: 'EnvironmentalInputs',
    TechnicalInputs: 'TechnicalInputs',
    ActivityAspectAreas: 'ActivityAspectAreas',
    Activity: 'Activity',
    Compliance: 'Compliance',
    Stakeholder: 'Stakeholder'
} as const;
export type TaskSummaryTypeEnum = typeof TaskSummaryTypeEnum[keyof typeof TaskSummaryTypeEnum];

/**
 * @export
 */
export const TaskSummaryStatusEnum = {
    New: 'New',
    Pending: 'Pending',
    Completed: 'Completed',
    Unavailable: 'Unavailable',
    Empty: 'Empty',
    Loading: 'Loading'
} as const;
export type TaskSummaryStatusEnum = typeof TaskSummaryStatusEnum[keyof typeof TaskSummaryStatusEnum];

/**
 * @export
 */
export const TaskSummaryWaitingForEnum = {
    AspectActivity: 'AspectActivity',
    Receptor: 'Receptor',
    Impact: 'Impact',
    Control: 'Control',
    Risk: 'Risk',
    EnvironmentalInputs: 'EnvironmentalInputs',
    TechnicalInputs: 'TechnicalInputs',
    ActivityAspectAreas: 'ActivityAspectAreas',
    Activity: 'Activity',
    Compliance: 'Compliance',
    Stakeholder: 'Stakeholder'
} as const;
export type TaskSummaryWaitingForEnum = typeof TaskSummaryWaitingForEnum[keyof typeof TaskSummaryWaitingForEnum];


/**
 * Check if a given object implements the TaskSummary interface.
 */
export function instanceOfTaskSummary(value: object): value is TaskSummary {
    return true;
}

export function TaskSummaryFromJSON(json: any): TaskSummary {
    return TaskSummaryFromJSONTyped(json, false);
}

export function TaskSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'] == null ? undefined : json['type'],
        'status': json['status'] == null ? undefined : json['status'],
        'percentage': json['percentage'] == null ? undefined : json['percentage'],
        'waitingFor': json['waitingFor'] == null ? undefined : json['waitingFor'],
        'unknownAnswers': json['unknown_answers'] == null ? undefined : json['unknown_answers'],
        'documentInfo': json['document_info'] == null ? undefined : EnvidDocumentInfoFromJSON(json['document_info']),
    };
}

export function TaskSummaryToJSON(value?: TaskSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'status': value['status'],
        'percentage': value['percentage'],
        'waitingFor': value['waitingFor'],
        'unknown_answers': value['unknownAnswers'],
        'document_info': EnvidDocumentInfoToJSON(value['documentInfo']),
    };
}

