/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportSummary } from './ReportSummary';
import {
    ReportSummaryFromJSON,
    ReportSummaryFromJSONTyped,
    ReportSummaryToJSON,
} from './ReportSummary';

/**
 * 
 * @export
 * @interface EbaseResultReportSummary
 */
export interface EbaseResultReportSummary {
    /**
     * 
     * @type {boolean}
     * @memberof EbaseResultReportSummary
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EbaseResultReportSummary
     */
    error?: string;
    /**
     * 
     * @type {ReportSummary}
     * @memberof EbaseResultReportSummary
     */
    data?: ReportSummary;
}

/**
 * Check if a given object implements the EbaseResultReportSummary interface.
 */
export function instanceOfEbaseResultReportSummary(value: object): value is EbaseResultReportSummary {
    return true;
}

export function EbaseResultReportSummaryFromJSON(json: any): EbaseResultReportSummary {
    return EbaseResultReportSummaryFromJSONTyped(json, false);
}

export function EbaseResultReportSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EbaseResultReportSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'] == null ? undefined : json['success'],
        'error': json['error'] == null ? undefined : json['error'],
        'data': json['data'] == null ? undefined : ReportSummaryFromJSON(json['data']),
    };
}

export function EbaseResultReportSummaryToJSON(value?: EbaseResultReportSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'error': value['error'],
        'data': ReportSummaryToJSON(value['data']),
    };
}

