/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaskSummary } from './TaskSummary';
import {
    TaskSummaryFromJSON,
    TaskSummaryFromJSONTyped,
    TaskSummaryToJSON,
} from './TaskSummary';

/**
 * 
 * @export
 * @interface EbaseResultListTaskSummary
 */
export interface EbaseResultListTaskSummary {
    /**
     * 
     * @type {boolean}
     * @memberof EbaseResultListTaskSummary
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EbaseResultListTaskSummary
     */
    error?: string;
    /**
     * 
     * @type {Array<TaskSummary>}
     * @memberof EbaseResultListTaskSummary
     */
    data?: Array<TaskSummary>;
}

/**
 * Check if a given object implements the EbaseResultListTaskSummary interface.
 */
export function instanceOfEbaseResultListTaskSummary(value: object): value is EbaseResultListTaskSummary {
    return true;
}

export function EbaseResultListTaskSummaryFromJSON(json: any): EbaseResultListTaskSummary {
    return EbaseResultListTaskSummaryFromJSONTyped(json, false);
}

export function EbaseResultListTaskSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EbaseResultListTaskSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'] == null ? undefined : json['success'],
        'error': json['error'] == null ? undefined : json['error'],
        'data': json['data'] == null ? undefined : ((json['data'] as Array<any>).map(TaskSummaryFromJSON)),
    };
}

export function EbaseResultListTaskSummaryToJSON(value?: EbaseResultListTaskSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'error': value['error'],
        'data': value['data'] == null ? undefined : ((value['data'] as Array<any>).map(TaskSummaryToJSON)),
    };
}

