/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RiskElement } from './RiskElement';
import {
    RiskElementFromJSON,
    RiskElementFromJSONTyped,
    RiskElementToJSON,
} from './RiskElement';

/**
 * 
 * @export
 * @interface RiskMatrix
 */
export interface RiskMatrix {
    /**
     * 
     * @type {string}
     * @memberof RiskMatrix
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskMatrix
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RiskMatrix
     */
    isDefault?: boolean;
    /**
     * 
     * @type {Array<RiskElement>}
     * @memberof RiskMatrix
     */
    likelihood?: Array<RiskElement>;
    /**
     * 
     * @type {Array<RiskElement>}
     * @memberof RiskMatrix
     */
    consequence?: Array<RiskElement>;
    /**
     * 
     * @type {Array<RiskElement>}
     * @memberof RiskMatrix
     */
    residualRisk?: Array<RiskElement>;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof RiskMatrix
     */
    matrix?: Array<Array<number>>;
    /**
     * 
     * @type {string}
     * @memberof RiskMatrix
     */
    riskPdf?: string;
}

/**
 * Check if a given object implements the RiskMatrix interface.
 */
export function instanceOfRiskMatrix(value: object): value is RiskMatrix {
    return true;
}

export function RiskMatrixFromJSON(json: any): RiskMatrix {
    return RiskMatrixFromJSONTyped(json, false);
}

export function RiskMatrixFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskMatrix {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'isDefault': json['is_default'] == null ? undefined : json['is_default'],
        'likelihood': json['likelihood'] == null ? undefined : ((json['likelihood'] as Array<any>).map(RiskElementFromJSON)),
        'consequence': json['consequence'] == null ? undefined : ((json['consequence'] as Array<any>).map(RiskElementFromJSON)),
        'residualRisk': json['residual_risk'] == null ? undefined : ((json['residual_risk'] as Array<any>).map(RiskElementFromJSON)),
        'matrix': json['matrix'] == null ? undefined : json['matrix'],
        'riskPdf': json['risk_pdf'] == null ? undefined : json['risk_pdf'],
    };
}

export function RiskMatrixToJSON(value?: RiskMatrix | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'is_default': value['isDefault'],
        'likelihood': value['likelihood'] == null ? undefined : ((value['likelihood'] as Array<any>).map(RiskElementToJSON)),
        'consequence': value['consequence'] == null ? undefined : ((value['consequence'] as Array<any>).map(RiskElementToJSON)),
        'residual_risk': value['residualRisk'] == null ? undefined : ((value['residualRisk'] as Array<any>).map(RiskElementToJSON)),
        'matrix': value['matrix'],
        'risk_pdf': value['riskPdf'],
    };
}

