/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnswerRequest
 */
export interface AnswerRequest {
    /**
     * 
     * @type {string}
     * @memberof AnswerRequest
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequest
     */
    type?: AnswerRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequest
     */
    answer?: AnswerRequestAnswerEnum;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequest
     */
    reason?: string;
    /**
     * 
     * @type {number}
     * @memberof AnswerRequest
     */
    likelihood?: number;
    /**
     * 
     * @type {number}
     * @memberof AnswerRequest
     */
    consequence?: number;
    /**
     * 
     * @type {number}
     * @memberof AnswerRequest
     */
    risk?: number;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequest
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerRequest
     */
    taskType?: AnswerRequestTaskTypeEnum;
}


/**
 * @export
 */
export const AnswerRequestTypeEnum = {
    Activity: 'Activity',
    ProjectCategory: 'ProjectCategory',
    Aspect: 'Aspect',
    Receptor: 'Receptor',
    Control: 'Control',
    TechnicalInput: 'TechnicalInput',
    EnvironmentalInput: 'EnvironmentalInput',
    Impact: 'Impact',
    Region: 'Region',
    Reference: 'Reference',
    ProjectActivityLink: 'ProjectActivityLink',
    AspectActivityLink: 'AspectActivityLink',
    ImpactReceptorLink: 'ImpactReceptorLink',
    ReceptorRegionLink: 'ReceptorRegionLink',
    ControlReceptorLink: 'ControlReceptorLink',
    ControlActivityLink: 'ControlActivityLink',
    ImpactType: 'ImpactType',
    ControlType: 'ControlType',
    ReceptorClassification: 'ReceptorClassification',
    ControlName: 'ControlName',
    ControlSource: 'ControlSource',
    ControlReceptorActivityLink: 'ControlReceptorActivityLink',
    Stakeholder: 'Stakeholder',
    StakeholderRegionLink: 'StakeholderRegionLink',
    ReceptorGisLink: 'ReceptorGisLink',
    Project: 'Project',
    ProjectMilestone: 'ProjectMilestone',
    ProjectRole: 'ProjectRole',
    ComplianceDefn: 'ComplianceDefn',
    ComplianceAction: 'ComplianceAction',
    ProjectStakeholderLink: 'ProjectStakeholderLink',
    StakeholderEvent: 'StakeholderEvent',
    StakeholderObjection: 'StakeholderObjection',
    StakeholderAction: 'StakeholderAction',
    StakeholderEventObjectionLink: 'StakeholderEventObjectionLink',
    GisWrapper: 'GisWrapper'
} as const;
export type AnswerRequestTypeEnum = typeof AnswerRequestTypeEnum[keyof typeof AnswerRequestTypeEnum];

/**
 * @export
 */
export const AnswerRequestAnswerEnum = {
    New: 'New',
    Yes: 'Yes',
    No: 'No',
    DontKnow: 'DontKnow'
} as const;
export type AnswerRequestAnswerEnum = typeof AnswerRequestAnswerEnum[keyof typeof AnswerRequestAnswerEnum];

/**
 * @export
 */
export const AnswerRequestTaskTypeEnum = {
    AspectActivity: 'AspectActivity',
    Receptor: 'Receptor',
    Impact: 'Impact',
    Control: 'Control',
    Risk: 'Risk',
    EnvironmentalInputs: 'EnvironmentalInputs',
    TechnicalInputs: 'TechnicalInputs',
    ActivityAspectAreas: 'ActivityAspectAreas',
    Activity: 'Activity',
    Compliance: 'Compliance',
    Stakeholder: 'Stakeholder'
} as const;
export type AnswerRequestTaskTypeEnum = typeof AnswerRequestTaskTypeEnum[keyof typeof AnswerRequestTaskTypeEnum];


/**
 * Check if a given object implements the AnswerRequest interface.
 */
export function instanceOfAnswerRequest(value: object): value is AnswerRequest {
    return true;
}

export function AnswerRequestFromJSON(json: any): AnswerRequest {
    return AnswerRequestFromJSONTyped(json, false);
}

export function AnswerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnswerRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'objectId': json['object_id'] == null ? undefined : json['object_id'],
        'type': json['type'] == null ? undefined : json['type'],
        'answer': json['answer'] == null ? undefined : json['answer'],
        'reason': json['reason'] == null ? undefined : json['reason'],
        'likelihood': json['likelihood'] == null ? undefined : json['likelihood'],
        'consequence': json['consequence'] == null ? undefined : json['consequence'],
        'risk': json['risk'] == null ? undefined : json['risk'],
        'projectId': json['project_id'] == null ? undefined : json['project_id'],
        'taskType': json['taskType'] == null ? undefined : json['taskType'],
    };
}

export function AnswerRequestToJSON(value?: AnswerRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object_id': value['objectId'],
        'type': value['type'],
        'answer': value['answer'],
        'reason': value['reason'],
        'likelihood': value['likelihood'],
        'consequence': value['consequence'],
        'risk': value['risk'],
        'project_id': value['projectId'],
        'taskType': value['taskType'],
    };
}

