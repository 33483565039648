/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ManageReportInstanceRequest
 */
export interface ManageReportInstanceRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ManageReportInstanceRequest
     */
    templateIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ManageReportInstanceRequest
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ManageReportInstanceRequest
     */
    reportType?: ManageReportInstanceRequestReportTypeEnum;
}


/**
 * @export
 */
export const ManageReportInstanceRequestReportTypeEnum = {
    ImpactAssessment: 'ImpactAssessment',
    ControlsSummary: 'ControlsSummary',
    Compliance: 'Compliance',
    Stakeholder: 'Stakeholder',
    RegionStakeholder: 'RegionStakeholder',
    GisImpactAssessment: 'GisImpactAssessment'
} as const;
export type ManageReportInstanceRequestReportTypeEnum = typeof ManageReportInstanceRequestReportTypeEnum[keyof typeof ManageReportInstanceRequestReportTypeEnum];


/**
 * Check if a given object implements the ManageReportInstanceRequest interface.
 */
export function instanceOfManageReportInstanceRequest(value: object): value is ManageReportInstanceRequest {
    return true;
}

export function ManageReportInstanceRequestFromJSON(json: any): ManageReportInstanceRequest {
    return ManageReportInstanceRequestFromJSONTyped(json, false);
}

export function ManageReportInstanceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ManageReportInstanceRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'templateIds': json['template_ids'] == null ? undefined : json['template_ids'],
        'projectId': json['project_id'] == null ? undefined : json['project_id'],
        'reportType': json['report_type'] == null ? undefined : json['report_type'],
    };
}

export function ManageReportInstanceRequestToJSON(value?: ManageReportInstanceRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'template_ids': value['templateIds'],
        'project_id': value['projectId'],
        'report_type': value['reportType'],
    };
}

