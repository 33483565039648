/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportTaskResult } from './ReportTaskResult';
import {
    ReportTaskResultFromJSON,
    ReportTaskResultFromJSONTyped,
    ReportTaskResultToJSON,
} from './ReportTaskResult';

/**
 * 
 * @export
 * @interface EbaseResultReportTaskResult
 */
export interface EbaseResultReportTaskResult {
    /**
     * 
     * @type {boolean}
     * @memberof EbaseResultReportTaskResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EbaseResultReportTaskResult
     */
    error?: string;
    /**
     * 
     * @type {ReportTaskResult}
     * @memberof EbaseResultReportTaskResult
     */
    data?: ReportTaskResult;
}

/**
 * Check if a given object implements the EbaseResultReportTaskResult interface.
 */
export function instanceOfEbaseResultReportTaskResult(value: object): value is EbaseResultReportTaskResult {
    return true;
}

export function EbaseResultReportTaskResultFromJSON(json: any): EbaseResultReportTaskResult {
    return EbaseResultReportTaskResultFromJSONTyped(json, false);
}

export function EbaseResultReportTaskResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): EbaseResultReportTaskResult {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'] == null ? undefined : json['success'],
        'error': json['error'] == null ? undefined : json['error'],
        'data': json['data'] == null ? undefined : ReportTaskResultFromJSON(json['data']),
    };
}

export function EbaseResultReportTaskResultToJSON(value?: EbaseResultReportTaskResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'error': value['error'],
        'data': ReportTaskResultToJSON(value['data']),
    };
}

