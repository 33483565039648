import React from 'react';
import sitedata from '../utils/sitedata'
import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import {Table} from 'react-bootstrap'
import EButton from "../components/common/EButton"
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import Spinner from "../components/common/Spinner"
import taskUtil from "../utils/taskUtil"
import HubHeader from "../components/common/HubHeader"
import HubBox from "../components/common/HubBox"
import envid from "../utils/envid"
import { SummaryItem} from '../components/common/SummaryTree'
import ProjectSidebarWrapper from "../components/common/ProjectSidebarWrapper"
import {ManageReportsViewContainer, ManageReportsModalContainer} from './ManageReportsView'
import PropTypes from 'prop-types';
import SessionManager from "../utils/SessionManager";
import {Trans, withTranslation} from "react-i18next";

class ControlsSummaryView extends React.Component {

    constructor()
    {
        super();
        this.state = {showHelp:true, showReportTemplates:false};

        this.selectAll = this.selectAll.bind(this);
        this.selectNone = this.selectNone.bind(this);
        this.changeAll = this.changeAll.bind(this);
        this.changeAnswer = this.changeAnswer.bind(this);
        this.renderObject = this.renderObject.bind(this);
    }

    showTask(type)
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        SessionManager.shared().goto("/report-controlssummary/" + project_id + "/task");
    }

    editProject()
    {
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;
        SessionManager.shared().goto("/projects/project/" + project_id+'/info');
    }

    changeAll(value)
    {
        let task = this.props.task;
        for (let i=0;i<task.object.structure.list.length;++i)
        {
            let object_id = task.object.structure.list[i].object_id;
            let answer = taskUtil.getAnswer(task, object_id);

            if (answer.answer !== value)
            {
                this.changeAnswer(answer);
            }

        }

    }

    selectObject(object)
    {
        // load the object from the server
        this.props.loadObject(object);
    }

    selectAll()
    {
        this.changeAll("Yes");
    }

    selectNone()
    {
        this.changeAll("No");
    }

    changeAnswer(answer)
    {
        // if they answer no to a control question, we have to ask the for a justification
        let params = sitedata.getParams(this.props);
        let changes = {project_id:params.projectId, taskType:"Activity"};
        changes.answer = (answer.answer === "Yes") ? "No" : "Yes";
        changes.reason = null;

        let newAnswer = Object.assign({}, answer, changes);
        this.props.updateAnswer(newAnswer);

        console.log("Changing The Answer - ");
        console.log(newAnswer)
    }

    renderObject(task, object)
    {
        let style_small_space = {marginRight:"5px"};

        let ret = [];

        let type = object.type;

        // lookup the answers
        let answer = taskUtil.getAnswer(task, object.object_id);

        let check = null;
        if (answer != null)
        {
            let icon = null;
            if (answer.answer === "Yes")
            {
                icon = "fa-check-square-o tree-item-checked";
            }
            else
            {
                icon = "fa-square-o  tree-item-unchecked";
            }

            check = <span className={"tree-item-check fa " + icon}
                          style={{ marginLeft:3, marginTop:2, fontSize:18, width:20}}
                          onClick={(e) => {e.stopPropagation(); this.changeAnswer(answer)}}/>
        }

        ret.push(<EnvidObjectIcon type={type} showName={false}/>);
        ret.push(<span style={style_small_space}/>);
        if (type === "Control")
        {
            let name = object.details.name;

            //let index = name.lastIndexOf(" from ");
            let index = name.indexOf(" for ");
            if (index > 0) {
                name = name.substring(0, index);
            }
            ret.push(name);
        }
        else
        {
            ret.push(object.details.name);
        }

        return <div>{check}<span className="tree-item" style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>&nbsp;{ret}</span></div>
    }

    renderChildren(task)
    {
        let children = [];
        for (let i=0; i<task.object.structure.list.length; ++i)
        {
            let activity_structure = task.object.structure.list[i];
            let object_id = activity_structure.object_id;
            let object =  taskUtil.getObject(task, "Activity", object_id);

            let controls = [];

            if (activity_structure.list!=null) {
                for (let j = 0; j < activity_structure.list.length; ++j) {
                    let control_id = activity_structure.list[j].object_id;
                    let control = taskUtil.getObject(task, "Control", control_id);
                    controls.push(<SummaryItem key={"control-" + control_id} object={control}/>);
                }
            }

            let child = <SummaryItem key={"activity-"+object_id} object={object}>{controls}</SummaryItem>
            children.push(child);
        }

        return children;
    }

    render() {

        const {t} = this.props;

        // console.log("ControlsSummary View");
        // console.log(this.props);
        let page_title = t("Controls Summary");
        let params = sitedata.getParams(this.props);
        let project_id = params.projectId;

        if (this.props.isLoading || this.props.isTasksLoading || this.props.report == null)
        {
            let p = null;
            if (this.props.projectName != null)
            {
                p = {name:this.props.projectName};
            }


            return <div>
                        <ContainerHeader title={this.props.makeTitle(p, page_title)} crumbs={['/', '/projects', '/projects/project/'+project_id+'/info', page_title]} dimensions={this.props.dimensions}/>
                        <PageWrapper dimensions={this.props.dimensions}>
                            <Spinner/>
                        </PageWrapper>
                    </div>
        }

        let report = this.props.report;
        let project = this.props.project;

        // let panelStyle = {width:"150px", height:"170px", padding:"5px", fontWeight:"bold", fontSize:"30px", margin:"10px"};

        // var navs = [];

        var total_percentage = 0;

        // navs = [];
        let titleStyle = {fontSize:"larger", fontWeight:"normal", borderBottom:"1px solid #DDD", marginTop:-10, paddingBottom:15}; // borderBottom:"2px solid black"
        // let titleStyleReport = {fontSize:"larger", fontWeight:"normal", marginTop:-10, paddingBottom:15}; // borderBottom:"2px solid black"
        // let titleStyle2 = {fontSize:"larger", fontWeight:"bold", borderBottom:"1px solid #DDD", marginTop:50}; // borderBottom:"2px solid black"


        // total_percentage = Math.round(total_percentage);


        // var help = null;
        // if (false) {//this.state.showHelp && total_percentage == 0 && false) {
        //     help = (
        //         <div className="row">
        //             <div className="col-lg-11">
        //                 <div className="ibox float-e-margins">
        //                     <div className="ibox-content text-center p-md">
        //                         <Trans i18nKey="control-summary-desc">
        //                         <h2>Work through these phases to complete your <span className="text-navy">Impact & Risk Assessment</span>.</h2>
        //                         <p>Describe the context - Identify the impacts - Select your controls - Rank your risks</p>
        //                         </Trans>
        //
        //                         </div>
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // }
        // let project_title = project.name;

        let lastUpdate = (docInfo) => (docInfo==null) ? <span></span> : <span>Last Updated {new Date(docInfo.last_update_time).toLocaleDateString()}<br/>by {docInfo.last_update_user_name}</span>;

        // let report_rows = report.reports.map((report,index) => {
        //     let label = "label-primary";
        //     let text = lastUpdate(report.documentInfo);
        //     if (report.status === "Error") {
        //         label = "label-danger";
        //         text = <span>Error Generating Report</span>
        //     } else if (report.status === "Generating") {
        //         label = "label-default";
        //     } else if (report.status === "Pending") {
        //         label = "label-warning";
        //         text = <span>{t("Waiting for Completion of Impact & Risk Assessment")}</span>;
        //     }
        //
        //     return (
        //     <tr key={index}>
        //         <td className="project-status"><span className={"label " + label}>{report.status}</span></td>
        //         <td className="project-title left" style={{textAlign:"left"}}><span
        //             style={{fontSize: "normal", fontWeight: "bold"}}><i className={"fa " + ((report.document_type==="WORD_DOCX")?"fa-file-word-o":"fa-file-excel-o")} style={{color:(report.document_type==="WORD_DOCX")?"#376198":"#3C744C"}}></i> {report.name}</span>
        //         </td>
        //         <td className="project-text" style={{whiteSpace:"nowrap"}}>{text}</td>
        //         <td className="project-actions">
        //             <EButton type="edit" isTable={true} disabled={report.status !== "Ready"}
        //                      onClick={() => window.location.assign("/ebase/envid-client/read-report-downoadreport/" + report.instance_id)}>{t("Download")}</EButton>&nbsp;
        //         </td>
        //     </tr>);
        // });


        let modal_body = <Spinner/>
        if (this.props.templateError !=  null)
        {
            modal_body = <p>Error loading Report Templates : {this.state.templateError}</p>
        }
        else if (this.props.templates !=  null)
        {
            var tbody;

            if(this.props.templates === 0){
                tbody = <tr><td>No report templates available</td></tr>
            }else{

                const rows = this.props.templates
                    //.sort((t1, t2) => (t1.activated != t2.activated) ? ((t1.activated)?1:0) : ((t1.name >= t2.name)?1:0))
                    .map((tpt, index) =>

                            <tr key={index}>
                                <td className="project-status" style={{width:70}}><span className={"label label-" + ((tpt.activated)?"info":"")}>{(tpt.activated?t("Include"):t("Exclude"))}</span></td>
                                <td className="project-title left" style={{textAlign:"left"}}><a
                                    style={{fontSize: "normal", fontWeight: "bold"}}><i className={"fa " + ((tpt.type==="WORD_DOCX")?"fa-file-excel-o":"fa-file-word-o")} style={{color:(tpt.type==="WORD_DOCX")?"#376198":"#3C744C"}}></i> {tpt.name}</a>
                                    <br/>
                                    {tpt.description}
                                </td>
                                <td className="project-actions" style={{width:70}}>
                                    {
                                        (!tpt.activated)?
                                            <EButton type="add" isTable={true} onClick={this.props.updateReportTemplate.bind(this, tpt.template_id, true)}>{t("Add")}</EButton>
                                            :
                                            <EButton type="remove" isTable={true} onClick={this.props.updateReportTemplate.bind(this, tpt.template_id, false)}>{t("Remove")}</EButton>
                                    }
                                </td>
                            </tr>
                        , this);

                tbody = <tbody>{rows}</tbody>;
            }

            modal_body = <Table>{tbody}</Table>
        }

        let names = [t("Select Actvities"), t("Generate Reports")];
        let status = [];
        // Work out the status for each hub box

        // Provide Context Status
        status.push(taskUtil.combineStatus([report.summary["Activity"]]));

        // Reports
        status.push("Ready");

        let box_header = (
            <HubHeader onSelect={(index) => SessionManager.shared().goto("/report-controlssummary/" + project_id + "/summary/" + index)} selected={params.tabIndex}>
                <HubBox name={names[0]} colour={envid.getObjectColours("Activity")} icon={envid.getObjectIcons("Activity")} status={status[0]} hasPoint={true}    key={0}/>
                <HubBox name={names[1]} colour={envid.getObjectColours("Report")}   icon={envid.getObjectIcons("Report")} status={status[1]} hasPoint={false}   key={4}/>
            </HubHeader>
        );
        let selected_block = null;
        let tabIndex = parseInt(params.tabIndex);
        if (tabIndex === 0 )
        {
            // console.log("Task...");
            // console.log(this.props.task);
            let task = this.props.task;
            let activity_list = null;

            if (task == null)
            {
                activity_list = <div><Spinner/></div>
            }
            else {

                let item = {name:t("Activities"), icon:"Activity", type:"Activity", desc:t("Select the Activities that are relevant to this project")}

                let status = report.summary[item.type];
                let label = "-warning";
                let label_text = "";
                let completion = "";
                let byline = null;
                let click = this.showTask.bind(this, item.type);
                let actions = <EButton type="edit" isTable={true} onClick={click}>{t('Edit')}</EButton>
                let unknown = null;
                let bar_colour = "";
                let project_status_width = "15%";//"130px";
                let project_completion_width = "30%";//"250px";
                // let project_text_width = "20%";//"200px";
                let project_action_width = "15%";//"100px";

                if (status != null && (status.status === "Completed" || status.status === "Pending"))
                {
                    if (status.unknown_answers != null && status.unknown_answers > 0)
                    {
                        unknown = <span className="label label-warning">{status.unknown_answers} {t("Unknown Answers")}</span>
                        bar_colour = "#f8ac59";
                    } else {
                        unknown = <span className="label label-primary">{status.unknown_answers} {t("Unknown Answers")}</span>
                    }
                }

                if (status == null)
                {
                    label_text = t("Unknown");
                    label = "";
                    actions = null;
                }
                else if (status.status === "Completed")
                {
                    label = "-primary";
                    label_text = t("Complete");
                    completion = [
                        <small>{t("Completed")} {status.percentage+"%"}</small>,
                        <div className="progress progress-mini">
                            <div style={{width:status.percentage+"%", background:bar_colour}} className="progress-bar"></div>
                        </div>
                    ];
                    if (status.document_info != null && status.document_info.last_update_time != null)
                    {
                        byline = t("Completed") + " " + new Date(status.document_info.last_update_time).toDateString();
                    } else {
                        byline = t("Completed") + " ";

                    }

                }
                else if (status.status === "Unavailable")
                {
                    label = "-default";
                    label_text = t("Not Ready");
                    actions = null;
                    click = null;
                    completion = [<small>{t("Waiting for completion of")}</small>,
                        <br/>];
                    if (status.waitingFor != null && status.waitingFor.length > 0)
                    {

                        for (let i=0; i<status.waitingFor.length; ++i)
                        {
                            let s = status.waitingFor[i];
                            let itm = taskUtil.findTaskItem(s);
                            if (itm != null)
                            {
                                let comma = (i===0)?"":" & ";
                                completion.push(<small>{comma}<EnvidObjectIcon type={itm.icon}/>&nbsp;{t(itm.name)}</small>)
                            }
                        }

                    }
                }
                else if (status.status === "Empty")
                {
                    label = "-danger";
                    label_text = t("Empty");
                    actions = null;
                    click = null;
                    completion = <Trans i18nKey="controls-review"><span>This task is empty<br/>Please review your answers to previous tasks</span></Trans>
                }
                else if (status.status === "New") {
                    label = "-success";
                    label_text = t("Ready")
                    byline = t("Ready for input");
                    completion = [
                        <small>Click to start</small>,
                        <div className="progress progress-mini">
                            <div style={{width:status.percentage+"%", background:bar_colour}} className="progress-bar"></div>
                        </div>
                    ];
                }
                else if (status.status === "Pending")
                {
                    label = "-warning";
                    label_text = t("In Progress")
                    completion = [
                        <small>Completed {status.percentage+"%"}</small>,
                        <div className="progress progress-mini">
                            <div style={{width:status.percentage+"%"}} className="progress-bar"></div>
                        </div>
                    ];
                    if (status.document_info != null && status.document_info.last_update_time != null)
                    {
                        byline = t("Completed") + " " + new Date(status.document_info.last_update_time).toDateString();
                    } else {
                        byline = t("Completed") + " ";

                    }

                    byline = t("Last Edit") + " " + new Date().toDateString();
                }
                else if(status.status === "Loading")
                {
                    label_text = status.status;
                    completion = <span>Task is updating. This can take a few minutes.</span>
                }
                else
                {
                    label_text = status.status;
                }

                if(this.props.readOnly){
                    actions = null;
                }

                selected_block = (
                    <div className="ibox float-e-margins">
                        <div className="ibox-content p-md">

                            <div style={titleStyle}><span style={{paddingBottom:5, paddingTop:20}}>{t("Select Activities")}</span></div>
                            <div className="project-list">
                                <Table className="table table-hover">
                                    <tbody>
                                    <tr onClick={click}>
                                        <td className="project-status" style={{width:project_status_width}}>
                                            <span className={"label label" + label}>{label_text}</span>
                                        </td>
                                        <td className="project-title">
                                            <EnvidObjectIcon type={item.icon}/>&nbsp;&nbsp;<strong>{item.name}</strong>
                                            <br/>
                                            <small>{byline}</small>
                                        </td>
                                        <td className="project-completion" style={{width:project_completion_width}}>
                                            {completion}
                                        </td>
                                        <td className="project-actions" style={{width:project_action_width}}>
                                            {actions}
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>

                        </div>

                    </div>);

            }

        }
        else if (tabIndex === 1)
        {
            selected_block = <ManageReportsViewContainer readOnly={this.props.readOnly} showReportTemplates={(b) => this.setState({showReportTemplates:b})}/>
        }
        return (
            <div>
                <ContainerHeader title={this.props.makeTitle(this.props.project, page_title)} crumbs={['/', '/projects', '/projects/project/'+project_id+'/info', page_title]} dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions}>

                    <ManageReportsModalContainer showReportTemplates={(b) => this.setState({showReportTemplates:b})}
                                                 show={this.state.showReportTemplates}
                    />

                    <ProjectSidebarWrapper project={project} editProject={this.editProject.bind(this)}>
                        {box_header}
                        {selected_block}
                    </ProjectSidebarWrapper>

                </PageWrapper>
            </div>
        )
    }

}

ControlsSummaryView.propTypes = {
    report : PropTypes.object,
    project : PropTypes.object,
    isLoading : PropTypes.bool,
};


export default withTranslation()(ControlsSummaryView)
