/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AnswerRequest,
  EbaseResultEnvidObjectResult,
  EbaseResultListReportSummary,
  EbaseResultListReportTemplateResult,
  EbaseResultListTaskSummary,
  EbaseResultReportResult,
  EbaseResultReportSummary,
  EbaseResultReportTaskResult,
  EbaseResultString,
  GenerateReportInstancesRequest,
  ManageReportInstanceRequest,
  ObjectRequest,
  ReportDefnRequest,
  ReportInstanceRequest,
  ReportInstanceStatusRequest,
  ReportTaskActionRequest,
  ReportTaskRequest,
} from '../models/index';
import {
    AnswerRequestFromJSON,
    AnswerRequestToJSON,
    EbaseResultEnvidObjectResultFromJSON,
    EbaseResultEnvidObjectResultToJSON,
    EbaseResultListReportSummaryFromJSON,
    EbaseResultListReportSummaryToJSON,
    EbaseResultListReportTemplateResultFromJSON,
    EbaseResultListReportTemplateResultToJSON,
    EbaseResultListTaskSummaryFromJSON,
    EbaseResultListTaskSummaryToJSON,
    EbaseResultReportResultFromJSON,
    EbaseResultReportResultToJSON,
    EbaseResultReportSummaryFromJSON,
    EbaseResultReportSummaryToJSON,
    EbaseResultReportTaskResultFromJSON,
    EbaseResultReportTaskResultToJSON,
    EbaseResultStringFromJSON,
    EbaseResultStringToJSON,
    GenerateReportInstancesRequestFromJSON,
    GenerateReportInstancesRequestToJSON,
    ManageReportInstanceRequestFromJSON,
    ManageReportInstanceRequestToJSON,
    ObjectRequestFromJSON,
    ObjectRequestToJSON,
    ReportDefnRequestFromJSON,
    ReportDefnRequestToJSON,
    ReportInstanceRequestFromJSON,
    ReportInstanceRequestToJSON,
    ReportInstanceStatusRequestFromJSON,
    ReportInstanceStatusRequestToJSON,
    ReportTaskActionRequestFromJSON,
    ReportTaskActionRequestToJSON,
    ReportTaskRequestFromJSON,
    ReportTaskRequestToJSON,
} from '../models/index';

export interface CheckReportLoadRequest {
    projectId: string;
    reportType?: CheckReportLoadReportTypeEnum;
}

export interface GetEnvidData3Request {
    objectRequest?: ObjectRequest;
}

export interface GetReportRequest {
    reportDefnRequest?: ReportDefnRequest;
}

export interface GetReportInstanceStatusRequest {
    reportInstanceStatusRequest?: ReportInstanceStatusRequest;
}

export interface GetReportInstancesRequest {
    reportInstanceRequest?: ReportInstanceRequest;
}

export interface GetTaskRequest {
    reportTaskRequest?: ReportTaskRequest;
}

export interface GetTemplates1Request {
    reportDefnRequest?: ReportDefnRequest;
}

export interface ManageReportInstancesRequest {
    manageReportInstanceRequest?: ManageReportInstanceRequest;
}

export interface RegenerateReportInstancesRequest {
    generateReportInstancesRequest?: GenerateReportInstancesRequest;
}

export interface ReportTaskActionOperationRequest {
    reportTaskActionRequest?: ReportTaskActionRequest;
}

export interface SaveAnswersRequest {
    answerRequest?: Array<AnswerRequest>;
}

export interface TriggerReportRequest {
    instanceId?: string;
}

/**
 * EbaseEnvidClientWSApi - interface
 * 
 * @export
 * @interface EbaseEnvidClientWSApiInterface
 */
export interface EbaseEnvidClientWSApiInterface {
    /**
     * 
     * @param {string} projectId 
     * @param {'ImpactAssessment' | 'ControlsSummary' | 'Compliance' | 'Stakeholder' | 'RegionStakeholder' | 'GisImpactAssessment'} [reportType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidClientWSApiInterface
     */
    checkReportLoadRaw(requestParameters: CheckReportLoadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultReportResult>>;

    /**
     */
    checkReportLoad(projectId: string, reportType?: CheckReportLoadReportTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultReportResult>;

    /**
     * 
     * @param {ObjectRequest} [objectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidClientWSApiInterface
     */
    getEnvidData3Raw(requestParameters: GetEnvidData3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    getEnvidData3(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {ReportDefnRequest} [reportDefnRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidClientWSApiInterface
     */
    getReportRaw(requestParameters: GetReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultReportResult>>;

    /**
     */
    getReport(reportDefnRequest?: ReportDefnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultReportResult>;

    /**
     * 
     * @param {ReportInstanceStatusRequest} [reportInstanceStatusRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidClientWSApiInterface
     */
    getReportInstanceStatusRaw(requestParameters: GetReportInstanceStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultReportSummary>>;

    /**
     */
    getReportInstanceStatus(reportInstanceStatusRequest?: ReportInstanceStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultReportSummary>;

    /**
     * 
     * @param {ReportInstanceRequest} [reportInstanceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidClientWSApiInterface
     */
    getReportInstancesRaw(requestParameters: GetReportInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListReportSummary>>;

    /**
     */
    getReportInstances(reportInstanceRequest?: ReportInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListReportSummary>;

    /**
     * 
     * @param {ReportTaskRequest} [reportTaskRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidClientWSApiInterface
     */
    getTaskRaw(requestParameters: GetTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultReportTaskResult>>;

    /**
     */
    getTask(reportTaskRequest?: ReportTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultReportTaskResult>;

    /**
     * 
     * @param {ReportDefnRequest} [reportDefnRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidClientWSApiInterface
     */
    getTemplates1Raw(requestParameters: GetTemplates1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListReportTemplateResult>>;

    /**
     */
    getTemplates1(reportDefnRequest?: ReportDefnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListReportTemplateResult>;

    /**
     * 
     * @param {ManageReportInstanceRequest} [manageReportInstanceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidClientWSApiInterface
     */
    manageReportInstancesRaw(requestParameters: ManageReportInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListReportSummary>>;

    /**
     */
    manageReportInstances(manageReportInstanceRequest?: ManageReportInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListReportSummary>;

    /**
     * 
     * @param {GenerateReportInstancesRequest} [generateReportInstancesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidClientWSApiInterface
     */
    regenerateReportInstancesRaw(requestParameters: RegenerateReportInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListReportSummary>>;

    /**
     */
    regenerateReportInstances(generateReportInstancesRequest?: GenerateReportInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListReportSummary>;

    /**
     * 
     * @param {ReportTaskActionRequest} [reportTaskActionRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidClientWSApiInterface
     */
    reportTaskActionRaw(requestParameters: ReportTaskActionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListTaskSummary>>;

    /**
     */
    reportTaskAction(reportTaskActionRequest?: ReportTaskActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListTaskSummary>;

    /**
     * 
     * @param {Array<AnswerRequest>} [answerRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidClientWSApiInterface
     */
    saveAnswersRaw(requestParameters: SaveAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListTaskSummary>>;

    /**
     */
    saveAnswers(answerRequest?: Array<AnswerRequest>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListTaskSummary>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidClientWSApiInterface
     */
    test1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    test1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} [instanceId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidClientWSApiInterface
     */
    triggerReportRaw(requestParameters: TriggerReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultString>>;

    /**
     */
    triggerReport(instanceId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultString>;

}

/**
 * 
 */
export class EbaseEnvidClientWSApi extends runtime.BaseAPI implements EbaseEnvidClientWSApiInterface {

    /**
     */
    async checkReportLoadRaw(requestParameters: CheckReportLoadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultReportResult>> {
        if (requestParameters['projectId'] == null) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter "projectId" was null or undefined when calling checkReportLoad().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['reportType'] != null) {
            queryParameters['reportType'] = requestParameters['reportType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/envid-client/read-report-checkLoad/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultReportResultFromJSON(jsonValue));
    }

    /**
     */
    async checkReportLoad(projectId: string, reportType?: CheckReportLoadReportTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultReportResult> {
        const response = await this.checkReportLoadRaw({ projectId: projectId, reportType: reportType }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEnvidData3Raw(requestParameters: GetEnvidData3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-client/read-report-object`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ObjectRequestToJSON(requestParameters['objectRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async getEnvidData3(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.getEnvidData3Raw({ objectRequest: objectRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReportRaw(requestParameters: GetReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultReportResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-client/read-report-load`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportDefnRequestToJSON(requestParameters['reportDefnRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultReportResultFromJSON(jsonValue));
    }

    /**
     */
    async getReport(reportDefnRequest?: ReportDefnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultReportResult> {
        const response = await this.getReportRaw({ reportDefnRequest: reportDefnRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReportInstanceStatusRaw(requestParameters: GetReportInstanceStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultReportSummary>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-client/read-report-instancestatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportInstanceStatusRequestToJSON(requestParameters['reportInstanceStatusRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultReportSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getReportInstanceStatus(reportInstanceStatusRequest?: ReportInstanceStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultReportSummary> {
        const response = await this.getReportInstanceStatusRaw({ reportInstanceStatusRequest: reportInstanceStatusRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReportInstancesRaw(requestParameters: GetReportInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListReportSummary>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-client/read-report-reportinstances`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportInstanceRequestToJSON(requestParameters['reportInstanceRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListReportSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getReportInstances(reportInstanceRequest?: ReportInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListReportSummary> {
        const response = await this.getReportInstancesRaw({ reportInstanceRequest: reportInstanceRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTaskRaw(requestParameters: GetTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultReportTaskResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-client/read-report-loadtask`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportTaskRequestToJSON(requestParameters['reportTaskRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultReportTaskResultFromJSON(jsonValue));
    }

    /**
     */
    async getTask(reportTaskRequest?: ReportTaskRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultReportTaskResult> {
        const response = await this.getTaskRaw({ reportTaskRequest: reportTaskRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTemplates1Raw(requestParameters: GetTemplates1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListReportTemplateResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-client/read-report-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportDefnRequestToJSON(requestParameters['reportDefnRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListReportTemplateResultFromJSON(jsonValue));
    }

    /**
     */
    async getTemplates1(reportDefnRequest?: ReportDefnRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListReportTemplateResult> {
        const response = await this.getTemplates1Raw({ reportDefnRequest: reportDefnRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async manageReportInstancesRaw(requestParameters: ManageReportInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListReportSummary>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-client/write-report-manageinstances`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ManageReportInstanceRequestToJSON(requestParameters['manageReportInstanceRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListReportSummaryFromJSON(jsonValue));
    }

    /**
     */
    async manageReportInstances(manageReportInstanceRequest?: ManageReportInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListReportSummary> {
        const response = await this.manageReportInstancesRaw({ manageReportInstanceRequest: manageReportInstanceRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async regenerateReportInstancesRaw(requestParameters: RegenerateReportInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListReportSummary>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-client/write-report-regenerateinstances`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateReportInstancesRequestToJSON(requestParameters['generateReportInstancesRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListReportSummaryFromJSON(jsonValue));
    }

    /**
     */
    async regenerateReportInstances(generateReportInstancesRequest?: GenerateReportInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListReportSummary> {
        const response = await this.regenerateReportInstancesRaw({ generateReportInstancesRequest: generateReportInstancesRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportTaskActionRaw(requestParameters: ReportTaskActionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListTaskSummary>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-client/write-report-taskaction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportTaskActionRequestToJSON(requestParameters['reportTaskActionRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListTaskSummaryFromJSON(jsonValue));
    }

    /**
     */
    async reportTaskAction(reportTaskActionRequest?: ReportTaskActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListTaskSummary> {
        const response = await this.reportTaskActionRaw({ reportTaskActionRequest: reportTaskActionRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveAnswersRaw(requestParameters: SaveAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListTaskSummary>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-client/write-report-saveanswers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['answerRequest']!.map(AnswerRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListTaskSummaryFromJSON(jsonValue));
    }

    /**
     */
    async saveAnswers(answerRequest?: Array<AnswerRequest>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListTaskSummary> {
        const response = await this.saveAnswersRaw({ answerRequest: answerRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async test1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/envid-client/test`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async test1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.test1Raw(initOverrides);
    }

    /**
     */
    async triggerReportRaw(requestParameters: TriggerReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultString>> {
        const queryParameters: any = {};

        if (requestParameters['instanceId'] != null) {
            queryParameters['instanceId'] = requestParameters['instanceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/envid-client/trigger-report-instance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultStringFromJSON(jsonValue));
    }

    /**
     */
    async triggerReport(instanceId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultString> {
        const response = await this.triggerReportRaw({ instanceId: instanceId }, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const CheckReportLoadReportTypeEnum = {
    ImpactAssessment: 'ImpactAssessment',
    ControlsSummary: 'ControlsSummary',
    Compliance: 'Compliance',
    Stakeholder: 'Stakeholder',
    RegionStakeholder: 'RegionStakeholder',
    GisImpactAssessment: 'GisImpactAssessment'
} as const;
export type CheckReportLoadReportTypeEnum = typeof CheckReportLoadReportTypeEnum[keyof typeof CheckReportLoadReportTypeEnum];
