/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProjectSmallResult } from './ProjectSmallResult';
import {
    ProjectSmallResultFromJSON,
    ProjectSmallResultFromJSONTyped,
    ProjectSmallResultToJSON,
} from './ProjectSmallResult';
import type { ReportSummary } from './ReportSummary';
import {
    ReportSummaryFromJSON,
    ReportSummaryFromJSONTyped,
    ReportSummaryToJSON,
} from './ReportSummary';
import type { TaskSummary } from './TaskSummary';
import {
    TaskSummaryFromJSON,
    TaskSummaryFromJSONTyped,
    TaskSummaryToJSON,
} from './TaskSummary';

/**
 * 
 * @export
 * @interface ReportResult
 */
export interface ReportResult {
    /**
     * 
     * @type {string}
     * @memberof ReportResult
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportResult
     */
    type?: ReportResultTypeEnum;
    /**
     * 
     * @type {{ [key: string]: TaskSummary; }}
     * @memberof ReportResult
     */
    summary?: { [key: string]: TaskSummary; };
    /**
     * 
     * @type {Array<ReportSummary>}
     * @memberof ReportResult
     */
    reports?: Array<ReportSummary>;
    /**
     * 
     * @type {ProjectSmallResult}
     * @memberof ReportResult
     */
    project?: ProjectSmallResult;
}


/**
 * @export
 */
export const ReportResultTypeEnum = {
    ImpactAssessment: 'ImpactAssessment',
    ControlsSummary: 'ControlsSummary',
    Compliance: 'Compliance',
    Stakeholder: 'Stakeholder',
    RegionStakeholder: 'RegionStakeholder',
    GisImpactAssessment: 'GisImpactAssessment'
} as const;
export type ReportResultTypeEnum = typeof ReportResultTypeEnum[keyof typeof ReportResultTypeEnum];


/**
 * Check if a given object implements the ReportResult interface.
 */
export function instanceOfReportResult(value: object): value is ReportResult {
    return true;
}

export function ReportResultFromJSON(json: any): ReportResult {
    return ReportResultFromJSONTyped(json, false);
}

export function ReportResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportResult {
    if (json == null) {
        return json;
    }
    return {
        
        'objectId': json['object_id'] == null ? undefined : json['object_id'],
        'type': json['type'] == null ? undefined : json['type'],
        'summary': json['summary'] == null ? undefined : (mapValues(json['summary'], TaskSummaryFromJSON)),
        'reports': json['reports'] == null ? undefined : ((json['reports'] as Array<any>).map(ReportSummaryFromJSON)),
        'project': json['project'] == null ? undefined : ProjectSmallResultFromJSON(json['project']),
    };
}

export function ReportResultToJSON(value?: ReportResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object_id': value['objectId'],
        'type': value['type'],
        'summary': value['summary'] == null ? undefined : (mapValues(value['summary'], TaskSummaryToJSON)),
        'reports': value['reports'] == null ? undefined : ((value['reports'] as Array<any>).map(ReportSummaryToJSON)),
        'project': ProjectSmallResultToJSON(value['project']),
    };
}

