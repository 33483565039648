/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RiskElement
 */
export interface RiskElement {
    /**
     * 
     * @type {number}
     * @memberof RiskElement
     */
    index?: number;
    /**
     * 
     * @type {string}
     * @memberof RiskElement
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskElement
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskElement
     */
    desc?: string;
}

/**
 * Check if a given object implements the RiskElement interface.
 */
export function instanceOfRiskElement(value: object): value is RiskElement {
    return true;
}

export function RiskElementFromJSON(json: any): RiskElement {
    return RiskElementFromJSONTyped(json, false);
}

export function RiskElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskElement {
    if (json == null) {
        return json;
    }
    return {
        
        'index': json['index'] == null ? undefined : json['index'],
        'code': json['code'] == null ? undefined : json['code'],
        'name': json['name'] == null ? undefined : json['name'],
        'desc': json['desc'] == null ? undefined : json['desc'],
    };
}

export function RiskElementToJSON(value?: RiskElement | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'index': value['index'],
        'code': value['code'],
        'name': value['name'],
        'desc': value['desc'],
    };
}

