/**
 * Created by craig on 17/01/17.
 */

import React from 'react'
import ebase from '../../utils/ajax'
import { connect } from 'react-redux'
import sitedata from "../../utils/sitedata"
import {updateAnswer, saveAnswersStart, saveAnswersSuccess, saveAnswersFailed} from '../../redux/actions/actions'
import {loadReportStart, loadReportDone} from '../../redux/actions/actions'
import {loadTaskStart, loadTaskSuccess, loadTaskFailed} from '../../redux/actions/actions'
import {loadSummaryObjectDone, loadSummaryObjectStart} from '../../redux/actions/actions'
import values from "object.values"
import ControlsSummaryView from "../../views/ControlsSummaryView";
import ActivityTaskView from "../../views/ActivityTaskView";
import SessionManager from "../../utils/SessionManager";

/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) =>
{
    let currentObject = state.reports.currentObject;
    let sData = state.session.siteData;
    return {
        report: (currentObject==null)? null : currentObject,
        project: (currentObject==null)? null : currentObject.project,
        isLoading: state.reports.isLoading,
        projectName : state.projects.projectName,
        task: state.reports.currentTask,
        unsavedAnswers:state.reports.unsavedAnswers,
        selectedIsLoading:state.summary.selectedIsLoading,
        selectedData:state.summary.selectedData,
        readOnly: !sitedata.checkWriteAccess(sData,"project"),
        siteData: sData,
        dimensions:state.session.dimensions,
        taskStatusLoading: state.reports.isTasksLoading

    }
}


// const loadReport = function(props, dispatch, project_id)
// {
//     dispatch(loadProjectStart());
//
//     ebase.ajax({
//         url:"/ebase/core/read-project-load",
//         data:{object_id : project_id},
//         success:(result) => {
//             if (result.success) {
//                 dispatch(loadProjectDone(result));
//             } else {
//                 props.showLoadError("Error Loading Project", result);
//             }
//         },
//         error :(result) => {
//             props.showLoadError("Error Loading Project", result);
//         }
//
//     });
//
// }

const saveAnswers = function(dispatch, unsavedAnswers)
{

    // console.log("Calling Save Answers - " + unsavedAnswers.answers);
    if (unsavedAnswers.answers == null || unsavedAnswers.savingAnswers != null) return;
    let answers = unsavedAnswers.answers;

    dispatch(saveAnswersStart());

    if (!Object.values) values.shim();

    // save the answer
    ebase.ajax({
        url:"/ebase/envid-client/write-report-saveanswers",
        data:Object.values(answers),
        success:(result) => {
            // once saved it will return all the changes, we'll then apply the changes to the model
            if (result.success) {
                dispatch(saveAnswersSuccess(result.data));
            } else {
                dispatch(saveAnswersFailed(result.error));
            }
        },
        error:() => dispatch(saveAnswersFailed("Couldn't contact server"))
    });

}

const mapDispatchToProps = (dispatch, ownProps) =>
{
    ownProps.showLoadError = SessionManager.shared().showLoadError;
    return {
        updateAnswer: (answer) => dispatch(updateAnswer(answer)),
        saveAnswers: (unsavedAnswers) => saveAnswers(dispatch, unsavedAnswers),

        loadReport: (project_id) => {

            dispatch(loadReportStart());
            ebase.ajax({
                url: "/ebase/envid-client/read-report-load",
                data: {project_id: project_id, report_type: "ControlsSummary"},
                success: (result) => {
                    if (result.success) {
                        console.log(' >>> controls summary load done: ', result.data);
                        dispatch(loadReportDone(result))

                    } else {
                        ownProps.showLoadError("Error Loading Project", result);
                    }
                },
                error: (result) => {
                    ownProps.showLoadError("Error Loading Project", result);
                }
            })

        },

        checkReportLoad : (project_id) => {

            dispatch({type:"CHECK_REPORT_TASKS_STATUS"});

            ebase.ajax({
                method: "GET",
                url: "/ebase/envid-client/read-report-checkLoad/"+project_id+"?reportType=ControlsSummary",
                success: (result) => {
                    if (result.success) {
                        dispatch(loadReportDone(result))

                        const task = result.data.summary[Object.keys(result.data.summary)[0]]

                        if (task.status != 'Loading') {

                            dispatch(loadTaskStart());

                            ebase.ajax({
                                url: "/ebase/envid-client/read-report-loadtask",
                                data: {project_id: project_id, report_type: "ControlsSummary", type: "Activity"},
                                success: (result) => {
                                    if (result.success) dispatch(loadTaskSuccess(result)); else dispatch(loadTaskFailed(result.error));
                                },
                                error: () => dispatch(loadTaskFailed("Error contacting server"))
                            });
                        }


                    } else {
                        ownProps.showLoadError("Error Loading Project", result);
                    }
                },
                error :(result) => {
                    ownProps.showLoadError("Error Loading Project", result);
                }
            })
        },


        makeTitle(project, page_name)
        {
            if (project == null) return page_name;
            return project.name + ": " + page_name;
        },

        loadObject: (object) => {
            let object_id = object.object_id;
            let displayed_object_id = (object.displayed_object_id == null ) ? object.object_id : object.displayed_object_id;
            dispatch(loadSummaryObjectStart(object_id, displayed_object_id));

            ebase.ajax({
                url: "/ebase/envid-client/read-report-object",
                data: {objectId: displayed_object_id},
                success: (result) => {
                    if (result.success) {
                        dispatch(loadSummaryObjectDone(displayed_object_id, result.data));
                    } else {
                        ownProps.showLoadError("Error Loading Summary", result);
                    }
                },
                error: (result) => {
                    ownProps.showLoadError("Error Loading Summary", result);
                }

            });
        }
    }
}

class ReportChildrenView extends React.Component{

    constructor(props) {
        super(props);

        this.checkLoaded = this.checkLoaded.bind(this);
        this.loadTimer = setInterval(this.checkLoaded, 280);

        let params = sitedata.getParams(this.props);
        let object_id = params.projectId;
        this.props.loadReport(object_id);
    }

    componentWillUnmount() {
        clearInterval(this.loadTimer);
    }

    render() {

        let params = sitedata.getParams(this.props);

        if (params.tabIndex == null){
            return (
                <div>
                    {React.cloneElement(<ActivityTaskView />, this.props)}
                </div>
            )
        }else{
            return (
                <div>
                    {React.cloneElement(<ControlsSummaryView />, this.props)}
                </div>
            )
        }


    }
    componentWillReceiveProps(nextProps)
    {
        if (nextProps.unsavedAnswers != null)
        {
            this.props.saveAnswers(nextProps.unsavedAnswers);
        }


    }

    // componentWillMount()
    // {
    //     let params = sitedata.getParams(this.props);
    //     let object_id = params.projectId;
    //     this.props.loadReport(object_id);
    //
    // }

    checkLoaded() {

        if (!!this.props.report){
            const task = this.props.report?.summary[Object.keys(this.props.report.summary)[0]];
            if (!this.props.taskStatusLoading && !!task && task.status === 'Loading'){
                console.log('ReportChildrenView checkLoaded');
                let params = sitedata.getParams(this.props);
                let object_id = params.projectId;
                this.props.checkReportLoad(object_id);
            }
        }
    }

};

const ControlsSummaryContainer = connect(mapStateToProps, mapDispatchToProps)(ReportChildrenView)

export default ControlsSummaryContainer
